@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-family: "Noto Sans", sans-serif;
  /* line-height: 4; */
}

body ::selection,
html ::selection {
  color: #36363c;
  background: #f1de64;
}
